import { Fragment } from "react";
import classes from "./Modal.module.css";
const Backdrop = () => {
    return <div className={classes.backdrop} />;
};

const ErrorModal = (props) => {
    return (
        <Fragment>
            <Backdrop />
            <div className={classes.modal}>
                <div>{props.heading}</div>
                <button className={classes.button} onClick={props.onClick}>
                    ok
                </button>
            </div>
        </Fragment>
    );
};

export default ErrorModal;
