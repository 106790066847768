import React, { useState, useEffect, useRef } from "react";
import classes from "./NewArticle.module.css";
import Modal from "../Components/Articles/Modal";
import "suneditor/dist/css/suneditor.min.css";
import { useHistory } from "react-router-dom";
import TextEditor from "../Components/TextEditor";
import { AiOutlineCloseCircle, AiFillCopy } from "react-icons/ai";
import copy from "copy-to-clipboard";
import { AiOutlineClose } from "react-icons/ai";
import LoadingSpinner from "../Components/LoadingSpinner";
import ErrorModal from "../Components/ErrorModal";

const NewArticle = () => {
  const [title, setTitle] = useState("");
  const [seoUrl, setSeoUrl] = useState("");
  const [seoUrlValidationMessage, setSeoUrlValidationMessage] = useState("");
  const latestRequestId = useRef(0);
  const controllerRef = useRef(null);
  const [isSeoUrlValid, setIsSeoUrlValid] = useState(false);
  const [shortDescription, setShortDescription] = useState("");
  const [content, setContent] = useState("");
  const [tags, setTags] = useState("");
  const [imageSelected, setImageSelected] = useState("");
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState("Careers & Training");
  const [author, setAuthor] = useState("");
  const [paidArticle, setPaidArticle] = useState(0);
  const [isPublished, setIsPublished] = useState(false);
  const [isPickedByEditor, setIsPickedByEditor] = useState(0);
  const [isFeaturedArticle, setIsFeaturedArticle] = useState(0);
  const [isDeleted, setIsDeleted] = useState(0);
  const [videoLink, setVideoLink] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [categoryID, setCategoryID] = useState([]);
  const [subCategoryID, setSubCategoryID] = useState("17269");
  const [selectedImages, setSelectedImages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [articleSource, setArticleSource] = useState("FTP");
  // const [chooseArticlePage, setChooseArticlePage] = useState("Course");
  const [showMultipleImageBtn, setShowMultipleImageBtn] = useState(false);
  const [showImages, setShowImages] = useState([]);
  const [pthumbImage, setPthumbImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [createdBy, setCreatedBy] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const [isSeoUrlEditable, setIsSeoUrlEditable] = useState(false);
  const [erorrshow, setErrorShow] = useState(false);
  const fullImagePath = "";
  // const seoUrl = `articles/${title
  //   ?.replace(/\W+/g, "-")
  //   .replace(/\-$/, "")
  //   .toLowerCase()}/{uuid}`;
  const history = useHistory();
  // Function to create SEO URL
  const createSeoUrl = (value) => {
    return `articles/${value
      ?.trim()
      .replace(/[^\w\s-]/g, "-")
      .replace(/\s+/g, "-")
      .replace(/-{2,}/g, "-")
      .replace(/(^-|-$)/g, "")
      .toLowerCase()}`
  };

  const validateSeoUrl = (value) => {
    if (!value) {
      setSeoUrlValidationMessage("SEO URL can't be empty.");
      setIsSeoUrlValid(false);
      return false;
    }
    const seoUrlRegex = /^[a-z0-9-]+$/;
    const noConsecutiveDashes = !value.includes("--");
    const noLeadingOrTrailingDash = !/^[-]|[-]$/.test(value);
    return seoUrlRegex.test(value) && noConsecutiveDashes && noLeadingOrTrailingDash;
  };


  const handleSeoUrlValidation = async (value) => {

    const requestId = ++latestRequestId.current;

    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    controllerRef.current = new AbortController();
    if (!value) {
      setIsSeoUrlValid(false);
      setSeoUrlValidationMessage("Seo Url cannot be empty");
      return
    }
    const formattedSeoUrl = value;
    const requestBody = { SeoURL: formattedSeoUrl };
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Token 77927b69bb144b065ca11bf2a9d452819cd852db",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
      signal: controllerRef.current.signal,
    };

    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/adminapi/CheckSeoURLinArticles",
        requestOptions
      );
      const result = await response.json();

      if (requestId === latestRequestId.current) {
        if (result.status_code === 200 && result.status === "SUCCESS") {
          if (result.IsSeoURLExist) {
            setIsSeoUrlValid(false);
            setSeoUrlValidationMessage("SEO URL already exists.");
          } else {
            setIsSeoUrlValid(true);
            setSeoUrlValidationMessage("SEO URL is correct.");
          }
        } else if (result.message === "Invalid SeoURL Format") {
          setIsSeoUrlValid(false);
          setSeoUrlValidationMessage("Invalid SeoURL. Use lowercase letters, numbers, starts with articles/ and single dashes.");
        }
        else {
          setIsSeoUrlValid(false);
          setSeoUrlValidationMessage("Invalid SEO URL.");
        }
      }
    } catch (error) {
      if (error.name !== "AbortError" && requestId === latestRequestId.current) {
        setSeoUrlValidationMessage("Error validating SEO URL.");
      }
    }
  };

  const handleTitleChange = (value) => {
    setTitle(value);
    if (value) {
      const generatedSeoUrl = createSeoUrl(value);
      setSeoUrl(generatedSeoUrl);
      setIsSeoUrlEditable(true);
      handleSeoUrlValidation(generatedSeoUrl);
    } else {
      setSeoUrl("");
      setIsSeoUrlEditable(false);
      setSeoUrlValidationMessage("");
    }
  };


  const handleSeoUrlChange = (value) => {
    setSeoUrl(value);

    if (true) {
      setSeoUrlValidationMessage("");
      setIsSeoUrlValid(true);
      handleSeoUrlValidation(value);
    } else {
      setSeoUrlValidationMessage(
        "Invalid SeoURL. Use lowercase letters, numbers, starts with articles/ and single dashes."
      );
      setIsSeoUrlValid(false);
    }
  };
  useEffect(() => {

  }, [seoUrl]);

  // console.log(isDeleted);

  const articlesource = [
    {
      type: "FTP",
    },
    {
      type: "Interns",
    },
    {
      type: "Rozgaar",
    },
    {
      type: "TA Team",
    },
  ];

  // const articlepage = [
  //   {
  //     type: "Course"
  //   },
  //   {
  //     type: "Event"
  //   },
  //   {
  //     type: "jobs"
  //   },
  //   {
  //     type: "HR Professionals"
  //   }
  // ]

  const imageHandleChange = (e) => {
    if (e.target.files) {
      const fileArray = Array.from(e.target.files).map((file) =>
        URL.createObjectURL(file)
      );
      setSelectedImages((prevImages) => prevImages.concat(fileArray));
      Array.from(e.target.files).map((file) => URL.revokeObjectURL(file));
    }
  };

  const onModalCloseHandler = () => {
    setIsModalOpen(false);
    history.push("/BiDashboard/articles");
  };

  useEffect(() => {
    getCategoryApi();
  }, []);

  useEffect(() => {
    categoryList.map((item) => {
      if (
        category.includes(item.Category) &&
        !categoryID.includes(item.ArticleId)
      ) {
        setCategoryID((prev) => [...prev, item.ArticleId]);
      }
    });
  }, [category]);

  useEffect(() => {
    subCategoryList.map((item) => {
      return (
        item.subCategory === subCategory && setSubCategoryID(item.ArticleId)
      );
    });
  }, [subCategory]);

  const removeTags = (str) => {
    let strippedString = "";
    if (str === null || str === "") return false;
    else strippedString = str.toString();
    strippedString = content.replace(/(<([^>]+)>)/gi, "");
    return strippedString.trim();
  };

  const getCategoryApi = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/adminapi/article-category", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "SUCCESS") {
          setCategoryList(result.Article);
          setSubCategoryList(result.Category);
        }
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => { }, []);

  const newArticleAPI = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();
    formdata.append("title", title);
    formdata.append("shortdescription", shortDescription);
    formdata.append("content", content);
    formdata.append("tags", tags);
    formdata.append("videourl", null);
    formdata.append("pickedby", isPickedByEditor);
    formdata.append("featuredarticle", isFeaturedArticle);
    formdata.append("isDeleted", isDeleted);
    formdata.append("isPublished", isPublished ? "1" : "0");
    formdata.append("category", categoryID);
    formdata.append("subcategory", subCategoryID);
    formdata.append("author", author);
    formdata.append("articleSource", articleSource);
    formdata.append("mobilethumb", pthumbImage);
    formdata.append("paidArticle", paidArticle);
    formdata.append("seourl", seoUrl);
    formdata.append("createdBy", createdBy);
    formdata.append(
      "multipleImg",
      showImages.map((item) => item.id).toString()
    );
    formdata.append("imageurl", imageSelected);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(process.env.REACT_APP_API_URL + "/adminapi/new-article", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code == 200 && result.status === "SUCCESS") {
          // editArticleAPI(result.data);
          setIsModalOpen(true);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const editArticleAPI = (id) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();
    formdata.append("id", id);
    formdata.append("seourl", seoUrl.replace("{uuid}", id));
    formdata.append("content", content);
    formdata.append("updatedBy", createdBy);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/edit-article",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.status_code == 200 && result.status === "SUCCESS") {
          // alert("Article created successfully!");
          setIsModalOpen(true);
          // history.push("/BiDashboard/articles");
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const multipleImagesUpload = (e) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();
    if (e !== undefined) {
      for (let index = 0; index < e.target.files.length; index++) {
        formdata.append("upload", e.target.files[index]);
      }
    }

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(global.apiLink + "/adminapi/uploadImagesMultiple", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        getmultipleImages();
      })
      .catch((error) => console.log("error", error));
  };

  const getmultipleImages = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var raw = "";
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      global.apiLink + "/adminapi/getNewUploadedImagesMultiple",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setShowImages(result.data || []);
      })
      .catch((error) => console.log("error", error));
  };

  const RemoveMultipleImage = (id) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/adminapi/removeImageFromArticles", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "SUCCESS") {
          alert("Image Remove Successfully");
          getmultipleImages();
        }
      })
      .catch((error) => console.log("error", error));
  };

  const removeCategory = (id) => {
    setCategory((preCat) => {
      return preCat.filter((item, index) => {
        return index !== id;
      });
    });
  };

  const removeCategoryId = (id) => {
    setCategoryID((prevId) => {
      return prevId.filter((item, index) => {
        return index !== id;
      });
    });
  };

  useEffect(() => {
    if (sessionStorage.getItem("googleBiEmail")) {
      setCreatedBy(sessionStorage.getItem("googleBiEmail"));
    }
  }, [sessionStorage.getItem("googleBiEmail")]);
  return (
    <>
      {erorrshow && (
        <ErrorModal
          heading={"Please provide a valid SEO URL"}
          onClick={() => setErrorShow(false)}
        />
      )}
      <div className={classes.NewArticle}>

        <h3 className={classes.ArticleHeading}>New Article</h3>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (isSeoUrlValid) {
              newArticleAPI();
              setShowMultipleImageBtn(true);
            }
            else {
              // alert("Please provide a valid SEO URL");
              setErrorShow(true)
            }
          }}
        >
          <div className={classes.FormContainer}>
            <div className={classes.leftSide}>
              <div className={classes.formGroup}>
                <label htmlFor="title">Title <span style={{ color: 'red' }} className="required">*</span> : </label>

                <input
                  type="text"
                  id="title"
                  name="title"
                  onChange={(e) => handleTitleChange(e.target.value)}
                  placeholder="Title"
                  required
                />
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="shortDescription">
                  Short Description <span style={{ color: 'red' }} className="required">*</span> : ( Length : {shortDescription.length}){" "}
                </label>
                <textarea
                  rows="4"
                  cols="50"
                  id="shortDescription"
                  name="shortDescription"
                  onChange={(e) => setShortDescription(e.target.value)}
                  placeholder="Write Description"
                  required
                />
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="Content">
                  Content : ( Length : {removeTags(content).length})
                </label>

                <div>
                  <TextEditor onChangeContent={setContent} value={content} />
                </div>
              </div>
              <div className={classes.submitDiv}>
                <button type="submit" className={classes.submitButton}>
                  Add
                </button>
              </div>
            </div>
            <div className={classes.rightSide}>
              <h5 className={classes.otherDetailHeading}>Other Details</h5>

              <div className={classes.formGroup}>
                <label htmlFor="image">Image <span style={{ color: 'red' }} className="required">*</span> : </label>
                <input
                  type="file"
                  required
                  name="image"
                  onChange={(e) => {
                    setImageSelected(e.target.files[0]);
                  }}
                />
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="image"> Multiple Image <span style={{ color: 'red' }} className="required">*</span> : </label>
                <input
                  multiple
                  type="file"
                  required
                  name="image"
                  onChange={(e) => {
                    multipleImagesUpload(e);
                  }}
                />
              </div>

              {/* <div className={classes.articlemultipleImagea}>{renderPhotos(selectedImages)}</div> */}
              <div className={classes.MapImg}>
                {showImages.map((item, index) => (
                  <div className={classes.ImageUrlContainer}>
                    <img
                      src={item.imageURL}
                      alt="hello"
                      className={classes.MultipleImgSelect}
                    />
                    <AiOutlineCloseCircle
                      className={classes.RemoveImgIcon}
                      onClick={() => RemoveMultipleImage(item.id)}
                    />
                    <div
                      onClick={() => {
                        copy(item.imageURL);
                        alert("Copied");
                      }}
                      className={classes.CopyURLIcon}
                    >
                      <AiFillCopy size={22} />
                    </div>
                  </div>
                ))}
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="image">Mobile Thumb <span style={{ color: 'red' }} className="required">*</span> : </label>
                <input
                  type="file"
                  required
                  name="image"
                  onChange={(e) => {
                    setPthumbImage(e.target.files[0]);
                  }}
                />
              </div>
              {/* <div className={classes.formGroup}>
                <label htmlFor="video">Video : </label>
                <input
                  type="text"
                  name="video"
                  value={videoLink}
                  placeholder="Youtube URL"
                  onChange={(e) => setVideoLink(e.target.value)}
                />
              </div> */}

              <div className={classes.formGroup}>
                <label htmlFor="tags">Tags <span style={{ color: 'red' }} className="required">*</span> : </label>
                <input
                  type="text"
                  name="tags"
                  required
                  placeholder="Tags"
                  value={tags}
                  onChange={(e) => setTags(e.target.value)}
                />
              </div>

              <div className={classes.formGroup}>
                <label for="cars">Category : </label>
                <select
                  name="category"
                  onChange={(e) => {
                    setCategory((prev) => {
                      return [...prev, e.target.value];
                    });
                  }}
                >
                  {" "}
                  <>
                    <option value="">--Select category--</option>
                  </>
                  {categoryList.map((item) => {
                    return (
                      <>
                        {category.includes(item.Category) ? null : (
                          <option
                            value={item.Category}
                            selected={category === item.Category}
                          >
                            {item.Category}
                          </option>
                        )}
                      </>
                    );
                  })}
                </select>
              </div>
              <div className={classes.Category_map}>
                {category.map((item, index) => {
                  return (
                    <div className={classes.CategoryMap}>
                      {item}
                      <div
                        onClick={() => {
                          removeCategory(index);
                          removeCategoryId(index);
                        }}
                      >
                        <AiOutlineClose />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className={classes.formGroup}>
                <label for="cars">Sub Category : </label>
                <select
                  name="subCategory"
                  onChange={(e) => setSubCategory(e.target.value)}
                >
                  {subCategoryList.map((item) => {
                    return (
                      <option
                        value={item.subCategory}
                        selected={subCategory === item.subCategory}
                      >
                        {item.subCategory}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className={classes.formGroup}>
                <label for="cars">Article Source : </label>
                <select
                  name="articleSource"
                  onChange={(e) => setArticleSource(e.target.value)}
                >
                  {articlesource.map((item) => {
                    return (
                      <option
                        value={item.type}
                        selected={articleSource === item.type}
                      >
                        {item.type}
                      </option>
                    );
                  })}
                </select>
              </div>

              {/* <div className={classes.formGroup}>
                <label for="cars">Choose Article Page : </label>
                <select
                  name="articlepage"
                  onChange={(e) => setChooseArticlePage(e.target.value)}
                >
                  {articlepage.map((item) => {
                    return (
                      <option
                        value={item.type}
                        selected={chooseArticlePage === item.type}
                      >
                        {item.type}
                      </option>
                    );
                  })}
                </select>
              </div> */}

              <div className={classes.formGroup}>
                <label htmlFor="author">Author <span style={{ color: 'red' }} className="required">*</span> : </label>
                <input
                  type="text"
                  name="author"
                  required
                  value={author}
                  placeholder="Author"
                  onChange={(e) => setAuthor(e.target.value)}
                />
              </div>
              {/* <div className={classes.formGroup}>
                <label htmlFor="seoUrl">SEO URL : </label>
                <input
                  type="text"
                  name="seoUrl"
                  disabled={true}
                  value={title ? seoUrl : ""}
                  placeholder="articles/article-title/{uuid}"
                />
              </div> */}
              <div className={classes.formGroup} style={{ position: "relative" }}>
                <label htmlFor="seoUrl">SEO URL <span style={{ color: 'red' }} className="required">*</span> :</label>
                <input
                  type="text"
                  id="seoUrl"
                  name="seoUrl"
                  value={seoUrl}
                  onChange={(e) => handleSeoUrlChange(e.target.value)}
                  placeholder="articles/article"
                  disabled={!isSeoUrlEditable}
                  required
                />
                {seoUrlValidationMessage && (
                  <div
                    className={`${classes.ValidationTooltip} ${isSeoUrlValid ? classes.successMessage : classes.errorMessage
                      }`}
                  >
                    {seoUrlValidationMessage}
                  </div>
                )}
              </div>




              <div className={classes.formGroup}>
                <label htmlFor="seoUrl">Created By : </label>
                <input
                  type="text"
                  name="CreatedBy"
                  disabled={true}
                  value={createdBy}
                  placeholder="created by"
                />
              </div>
              <div className={classes.CheckBoxStyle}>
                <div>
                  <div className={classes.formGroup}>
                    <div className={classes.checkBoxGroup}>
                      <label htmlFor="pickedBy">Paid Article : </label>
                      <input
                        type="checkbox"
                        name="PickedBy"
                        value={paidArticle}
                        onChange={() => setPaidArticle(!paidArticle ? 1 : 0)}
                      />
                    </div>
                  </div>
                  <div className={classes.formGroup}>
                    <div className={classes.checkBoxGroup}>
                      <label htmlFor="pickedBy">Editor's Pick : </label>
                      <input
                        type="checkbox"
                        name="PickedBy"
                        value={isPickedByEditor}
                        onChange={() =>
                          setIsPickedByEditor(!isPickedByEditor ? 1 : 0)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className={classes.formGroup}>
                    <div className={classes.checkBoxGroup}>
                      <label htmlFor="featuredArticle">
                        Featured Article :{" "}
                      </label>
                      <input
                        type="checkbox"
                        name="PickedBy"
                        value={isFeaturedArticle}
                        onChange={() =>
                          setIsFeaturedArticle(!isFeaturedArticle ? 1 : 0)
                        }
                      />
                    </div>
                  </div>

                  <div className={classes.formGroup}>
                    <div className={classes.checkBoxGroup}>
                      <label htmlFor="featuredArticle">Live : </label>
                      <input
                        type="checkbox"
                        name="PickedBy"
                        checked={isPublished}
                        onClick={() => setIsPublished(!isPublished)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="pickedBy">@Times Ascent</label>
              </div>
            </div>
          </div>
        </form>
      </div>
      <Modal isModalOpen={isModalOpen} onClose={onModalCloseHandler} />
      {loading && (
        <div className={classes.loaderContainer}>
          <LoadingSpinner />
        </div>
      )}
    </>
  );
};

export default NewArticle;
