import React, { useState, useEffect, useRef } from 'react'
import Modal from 'react-bootstrap/Modal';
import classes from "./EditCompanyModal.module.css"
import useOnClickOutside from '../useCustomHooks/useOnClickOutside';
import { ImCross } from 'react-icons/im';
import Loader from 'react-loader-spinner';
import ErrorModal from '../../Components/ErrorModal';

const EditCompanyModal = (props) => {
    const handleClose = () => props.setShow(false);
    const [seoUrl, setSeoUrl] = useState("");
    const [seoUrlValidationMessage, setSeoUrlValidationMessage] = useState("");
    const latestRequestId = useRef(0);
    const controllerRef = useRef(null);
    const [isSeoUrlValid, setIsSeoUrlValid] = useState(false);

    const [data, setData] = useState({});
    const [mobile, setMobile] = useState(null);
    const [loading, setLoading] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [compDesc, setCompDesc] = useState()
    const [selectedLoc, setSelectedLoc] = useState()
    const [address, setAddress] = useState()
    const [email, setEmail] = useState()
    const [industry, setIndustry] = useState()
    const [governmentCompany, setGovernmentCompany] = useState()

    const [existingLoc, setExistingLoc] = useState()
    const [website, setWebsite] = useState()
    const [companySuggestions, setCompanySuggestions] = useState([]);
    const [companySuggestionsShow, setCompanySuggestionsShow] = useState(true);
    const [selectedCompId, setSelectedCompId] = useState();
    const [companyLogo, setCompanyLogo] = useState();
    const [saveLoading, setSaveLoading] = useState(false);
    const [isSeoUrlEditable, setIsSeoUrlEditable] = useState(false);
    const [erorrshow, setErrorShow] = useState(false);
    const createSeoUrl = (value) => {
        return `NewCompanyProfile/${value
            ?.trim()
            .replace(/[^\w\s-]/g, "-")
            .replace(/\s+/g, "-")
            .replace(/-{2,}/g, "-")
            .replace(/(^-|-$)/g, "")
            .toLowerCase()}`
    };

    const handleTitleChange = (value) => {
        setCompanyName(value);
        if (value) {
            const generatedSeoUrl = createSeoUrl(value);
            setSeoUrl(generatedSeoUrl);
            setIsSeoUrlEditable(true);
            handleSeoUrlValidation(generatedSeoUrl);
        } else {
            setSeoUrl("");
            setIsSeoUrlEditable(false);
            setSeoUrlValidationMessage("");
        }
    };

    useEffect(() => {
        if (props.show)
            getCompanyDetail(props.companyId);

    }, [props.show]);
    const userEmail = sessionStorage.getItem("googleBiEmail")

    // save company api
    const saveCompany = async (e) => {
        setSaveLoading(true)
        let isCompanyExists = false
        await props.companyList.filter((val) => {
            if (val.compName.toLowerCase().toString() === (companyName.toLowerCase())) {
                setSelectedLoc(val.keyindex)
                isCompanyExists = true;
                return;
            }
        })
        e.preventDefault();
        // handleClose()


        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");

        var formdata = new FormData();
        if (isCompanyExists)
            formdata.append("companyId", selectedCompId);

        formdata.append("name", companyName);
        formdata.append("companyDescription", compDesc || "");
        formdata.append("phone", mobile || "");
        formdata.append("website", website || "");
        formdata.append("email", email || "");
        formdata.append("industry", industry || "");
        formdata.append("governmentCompany", governmentCompany ? "1" : "0");
        formdata.append("address", address || "");
        formdata.append("locationV2", selectedLoc || "");
        formdata.append("image", companyLogo || "");
        if (props.orderNumber)
            formdata.append("orderNumber", props.orderNumber);
        formdata.append("userEmail", userEmail);
        formdata.append("CompanySeourl", seoUrl)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        // if (isCompanyExists && props.createCompany) {
        //     alert("This company already exists")
        //     return;
        // }
        fetch(`${global.apiLink}/adminapi/${isCompanyExists ? "UpdateCompanyDetails" : "createCompanyForOrderNumber"}`, requestOptions)
            .then(response => response.json())
            .then((result) => {

                if (result.status_code === 200) {
                    // history.push('/JobPostingDetail/' + id +"/" + selectedCompId)
                    if (props.createCompany) {
                        alert("company updated")
                    }
                    if (props.adDetailsApi) {
                        props.adDetailsApi()
                        alert("company updated")
                    }
                    handleClose();
                } else {
                    alert("Company not updated")
                }
            })
            .catch(error => alert("company not updated"))
            .finally(() => setSaveLoading(false));
    }

    useEffect(() => {
        if (companyName !== "") {
            let val = false;
            setCompanySuggestions(props.companyList.filter((val) => {
                if (val.compName === companyName) {
                    val = true
                }
                return (val.compName ? val.compName.toLowerCase().toString().startsWith(companyName.toLowerCase()) : null)
            }))
            if (val) {
                setCompanySuggestionsShow(false)

            }
            setCompanySuggestionsShow(true)
        }
        else {
            setCompanySuggestions([])
            setCompanySuggestionsShow(false)
        }
    }, [companyName])

    const handleSeoUrlValidation = async (value) => {
        const requestId = ++latestRequestId.current;

        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        controllerRef.current = new AbortController();
        if (!value) {
            setIsSeoUrlValid(false);
            setSeoUrlValidationMessage("Seo Url cannot be empty");
            return
        }
        const formattedSeoUrl = value;
        const requestBody = { CompanySeoURL: formattedSeoUrl };
        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: "Token 77927b69bb144b065ca11bf2a9d452819cd852db",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
            signal: controllerRef.current.signal,
        };

        try {
            const response = await fetch(
                `${global.apiLink}/adminapi/CheckSeoURLinCompanies`,
                requestOptions
            );
            const result = await response.json();

            if (requestId === latestRequestId.current) {
                if (result.status_code === 200 && result.status === "SUCCESS") {
                    if (result.IsSeoURLExist) {
                        setIsSeoUrlValid(false);
                        setSeoUrlValidationMessage("SEO URL already exists.");
                    } else {
                        setIsSeoUrlValid(true);
                        setSeoUrlValidationMessage("SEO URL is correct.");
                    }
                } else if (result.message === "Invalid SeoURL Format") {
                    setIsSeoUrlValid(false);
                    setSeoUrlValidationMessage("Invalid SeoURL. Use lowercase letters, numbers, starts with NewCompanyProfile/ and single dashes.");
                }
                else {
                    setIsSeoUrlValid(false);
                    setSeoUrlValidationMessage("Invalid SeoURL. Use lowercase letters, numbers, starts with NewCompanyProfile/ and single dashes.");
                }
            }
        } catch (error) {
            if (error.name !== "AbortError" && requestId === latestRequestId.current) {
                setSeoUrlValidationMessage("Error validating SEO URL.");
            }
        }
    };


    const handleSeoUrlChange = (value) => {
        setSeoUrl(value)
        if (true) {
            setSeoUrlValidationMessage("");
            setIsSeoUrlValid(true);
            handleSeoUrlValidation(value);
        } else {
            setSeoUrlValidationMessage(
                "Invalid SeoURL. Use lowercase letters, numbers, starts with articles/ and single dashes."
            );
            setIsSeoUrlValid(false);
        }
    };
    useEffect(() => {

    }, [seoUrl]);

    const getCompanyDetail = (companyId, companyName) => {

        setCompanySuggestionsShow(false);
        setSelectedCompId(companyId)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "CompanyId": companyId
        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(`${global.apiLink}/v1/api/apiTimes/GetCompanyDetail`, requestOptions)
            .then(response => response.json())
            .then(result => {

                setData(result.data)
                setCompanyName(result.data.CompanyName || "");
                setCompDesc(result.data.companyDescription || "")
                setAddress(result.data.address || "")
                setIndustry(result.data.IndustryId || "")
                setWebsite(result.data.website || "")
                setMobile(result.data.phone || "")
                setEmail(result.data.email || "")
                setExistingLoc(result.data.city || "")
                setSelectedLoc(result.data.locid || "")
                if (result.data.CompanySeourl) {
                    setSeoUrl(result.data.CompanySeourl);
                    setIsSeoUrlEditable(true);
                } else {
                    setSeoUrl("");
                    setIsSeoUrlEditable(false);
                }
            })
            .catch(error => console.log('error', error));
    }
    const companyNameRef = useRef(null)
    useOnClickOutside(companyNameRef, () => setCompanySuggestionsShow(false))
    const [uploadedImage, setUploadedImage] = useState()
    return (
        <>
            {erorrshow && (
                <ErrorModal
                    heading={"Please provide a valid SEO URL"}
                    onClick={() => setErrorShow(false)}
                />
            )}

            <Modal
                show={props.show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                // size="lg"
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Body>
                    {props.loading ? <div className={classes.center}>
                        <Loader type="TailSpin" color="#00BFFF" height={80} width={80} />

                    </div> : <div className={classes.d_flex}>
                        <div className={classes.imgDiv}>
                            {<img src={props.createJob && uploadedImage ? URL.createObjectURL(uploadedImage) : props.adDetailImage} width="100%" height={props.createJob ? "90%" : "100%"} />}
                            {props.createJob ? <input type="file" accept="image/*" onChange={(e) => { setUploadedImage(e.target.files[0]) }} /> : <></>}
                        </div>
                        <form className={classes.formDiv} onSubmit={(e) => {
                            e.preventDefault();
                           saveCompany(e) 
                            
                        }}>
                            <div className={classes.header}>
                                <h4>Company Details</h4>
                                <ImCross onClick={handleClose} />
                            </div>
                            <div>

                                <div>
                                    <label>Company Name<span>*</span></label>
                                    {/* <input type="text" value={companyName} onChange={(e) => { setCompanyName(e.target.value) }} required /> */}
                                    <input
                                        type="text"
                                        id="title"
                                        name="title"
                                        value={companyName}
                                        onChange={(e) => handleTitleChange(e.target.value)}
                                        required
                                    />
                                    {/* {!props.createCompany ? <> */}

                                    {companySuggestionsShow && companySuggestions.length > 0 ? <div className={classes.suggestion} ref={companyNameRef}> <div>
                                        {
                                            companySuggestions.map((val) => {
                                                return <p onClick={() => {
                                                    getCompanyDetail(val.keyindex, val.compName)
                                                }}>
                                                    {val.compName}
                                                </p>
                                            })
                                        }
                                    </div>
                                    </div>
                                        :
                                        <></>
                                    }
                                    {/* </> : <></>
                                    } */}
                                </div>
                                <div>
                                    <label>Industry<span>*</span></label>
                                    <select value={industry} required onChange={(e) => { setIndustry(e.target.value) }}>
                                        <option value="">Choose Industry</option>
                                        {
                                            props.industryList.map((item) => {
                                                return (
                                                    <option value={item.id}>{item.industryName}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div>
                                    <label>Company Description</label>
                                    <textarea rows={"5"} value={compDesc} onChange={(e) => { setCompDesc(e.target.value) }} />
                                </div>
                                <div>
                                    <label>Tel</label>
                                    <input type="text" value={mobile} onChange={(e) => { setMobile(e.target.value) }} />
                                </div>
                                <div>
                                    <label>Email</label>
                                    <input type="email" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                </div>
                                <div>
                                    <label>Website</label>
                                    <input type="text" value={website} onChange={(e) => { setWebsite(e.target.value); }} />
                                </div>
                                <div>
                                    <label>Address</label>
                                    <textarea rows={"5"} value={address} onChange={(e) => { setAddress(e.target.value) }} />
                                </div>

                                <div>
                                    <label>Location<span>*</span></label>
                                    <select value={selectedLoc} required onChange={(e) => { setSelectedLoc(e.target.value) }}>
                                        <option value="">Choose location</option>
                                        {
                                            props.locationList.map((item) => {
                                                return (
                                                    <option value={item.id}>{item.locName}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div>
                                    <label>Company logo</label>
                                    <input type="file" onChange={(e) => { setCompanyLogo(e.target.files[0]) }} />
                                    {companyLogo || data.imagePath ? <img src={companyLogo ? URL.createObjectURL(companyLogo) : global.apiLink + "" + data.imagePath} height={200} /> : <></>}
                                </div>
                                <div className={classes.text_left}>
                                    <label>Government Organization</label>
                                    <input type="checkbox" onChange={(e) => { setGovernmentCompany(e.target.checked) }} />
                                </div>
                                <div style={{ position: "relative" }}>
                                    {seoUrlValidationMessage && (
                                        <div
                                            className={`${classes.ValidationTooltip} ${isSeoUrlValid ? classes.successMessage : classes.errorMessage}`}
                                        >
                                            {seoUrlValidationMessage}
                                        </div>
                                    )}
                                    <label>SeoURL<span>*</span></label>
                                    <input
                                        type="text"
                                        id="seoUrl"
                                        name="seoUrl"
                                        value={seoUrl}
                                        onChange={(e) => handleSeoUrlChange(e.target.value)}
                                        placeholder="NewCompanyProfile/company-name"
                                        disabled={!isSeoUrlEditable}
                                        required
                                    />
                                </div>


                                <div className={classes.buttons}>
                                    {saveLoading ? <Loader type="TailSpin" color="#f90292" height={30} width={30} /> : <button>Add</button>}</div>
                            </div>
                        </form>
                    </div>}
                </Modal.Body>

            </Modal>
        </>
    );
}

export default EditCompanyModal
